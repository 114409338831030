<template>
    <div class="page">
        <div class="container" v-if="cart != null">
            <div class="row">
                <div class="col-sm-6">
                    <div class="checkout">
                        <div class="checkout__title">Вашите данни</div>

                        <div class="contact">
                            <div class="contact__title">Име</div>
                            <div class="contact__value">{{ user.name }}</div>
                        </div>

                        <div class="contact">
                            <div class="contact__title">Телефон</div>
                            <div class="contact__value">{{ user.phone }}</div>
                        </div>

                        <div class="payment">
                            <div class="payment__title">Методи на плащане</div>
                            <div class="payment__items">
                                <div
                                        v-for="payment in payments"
                                        class="payment-item"
                                        @click="paymentChoose(payment)"
                                        :class="{'payment-item--active': paymentId === payment.id, 'payment-item--add': payment.type === 'CARD_ADD'}">
                                    <div class="payment-item__image"><img :src="payment.icon"></div>
                                    <div class="payment-item__title">{{ payment.title }} <i v-if="payment.id > 0" @click="paymentDeleteModal(payment)" class="material-icons">delete</i></div>
                                    <div v-if="payment.type !== 'CARD_ADD'" class="payment-item__checkbox"></div>
                                </div>
                            </div>
                            <div class="payment__hint">Плащането с карта е бързо и сигурно. Всички операции се извършват от страната на банката.</div>
                        </div>


                        <div class="address" v-if="address != null">
                            <div class="address__title">Доставка за <template v-if="!cart.slot.text.includes('Днес') && !cart.slot.text.includes('Утре')">на </template>{{ cart.slot.text.toLowerCase() }}</div>
                            <div class="address__street">{{ address.street }}<template v-if="address.house != null && address.house.length > 0">, {{ address.house }}</template></div>
                            <div class="address__details">
                                <div class="row mx-md-n4">
                                    <div class="col px-md-1">
                                        <div class="input-wrapper">
                                            <input v-on:keyup="addressChanged()" v-model="address.entrance" type="text" class="input-wrapper__input" placeholder=" " autocomplete="off"/>
                                            <label class="input-wrapper__label">Вход</label>
                                            <div @click="address.entrance = ''" v-if="address.entrance != null && address.entrance.length > 0" class="input-wrapper__clear"></div>
                                        </div>
                                    </div>
                                    <div class="col px-md-1">
                                        <div class="input-wrapper">
                                            <input v-on:keyup="addressChanged()" v-model="address.floor" type="text" class="input-wrapper__input" placeholder=" " autocomplete="off"/>
                                            <label class="input-wrapper__label">Етаж</label>
                                            <div @click="address.floor = ''" v-if="address.floor != null && address.floor.length > 0" class="input-wrapper__clear"></div>
                                        </div>
                                    </div>
                                    <div class="col px-md-1">
                                        <div class="input-wrapper">
                                            <input v-on:keyup="addressChanged()" v-model="address.apartment" type="text" class="input-wrapper__input" placeholder=" " autocomplete="off"/>
                                            <label class="input-wrapper__label">Апартамент</label>
                                            <div @click="address.apartment = ''" v-if="address.apartment != null && address.apartment.length > 0" class="input-wrapper__clear"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mx-md-n4">
                                    <div class="col px-md-1">
                                        <div class="input-wrapper">
                                            <textarea v-on:keyup="addressChanged()" v-model="address.comment" class="input-wrapper__input" placeholder=" " autocomplete="off"></textarea>
                                            <label class="input-wrapper__label">Коментар за курьер</label>
                                            <div @click="address.comment = ''" v-if="address.comment != null && address.comment.length > 0" class="input-wrapper__clear"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--<div class="coupons">-->
                            <!--<div class="coupons__title">Применить промокод</div>-->
                            <!--<div class="coupons__input">-->
                                <!--<div class="row">-->
                                    <!--<div class="col-sm-8">-->
                                        <!--<div class="input-wrapper">-->
                                            <!--<input v-model="coupon" type="text" class="input-wrapper__input" placeholder=" " autocomplete="off"/>-->
                                            <!--<label class="input-wrapper__label">Купон</label>-->
                                            <!--<div @click="coupon = ''" v-if="coupon != null && coupon.length > 0" class="input-wrapper__clear"></div>-->
                                        <!--</div>-->
                                    <!--</div>-->
                                    <!--<div class="col-sm-4">-->
                                        <!--<input class="btn btn-tilda" type="button" value="Прилагане"/>-->
                                    <!--</div>-->
                                <!--</div>-->

                            <!--</div>-->
                        <!--</div>-->

                        <div class="summary">
                            <div class="summary__field">
                                <div class="summary__field-name">Доставка</div>
                                <div class="summary__field-value"><span v-if="cart.delivery.price.normal !== cart.delivery.price.special" class="summary__field-value--discount">{{ cart.delivery.price.normal }} лв.</span> {{ cart.delivery.price.special }} лв.</div>
                            </div>
                            <div class="summary__field" v-if="cart.price.discount > 0">
                                <div class="summary__field-name">Отстъпки</div>
                                <div class="summary__field-value">-{{ cart.price.discount }} лв.</div>
                            </div>
                            <div class="summary__field">
                                <div class="summary__field-name summary__field-name--bold">Обща стойност на поръчката</div>
                                <div class="summary__field-value">{{ cart.price.special }} лв.</div>
                            </div>

                            <div v-if="this.$store.state.error.showErrorMessage" class="alert alert-danger" role="alert">
                                {{ this.$store.state.error.errorMessage }}
                            </div>

                            <input @click="checkoutSubmit()" class="btn btn-tilda" type="button" value="Поръчай"/>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <Cart />

                    <div class="collector">
                        <div class="input-wrapper">
                            <textarea v-on:keyup="commentChanged()" v-model="comment" class="input-wrapper__input" placeholder=" " autocomplete="off"></textarea>
                            <label class="input-wrapper__label">Коментар за събирач</label>
                            <div @click="comment = ''" v-if="comment != null && comment.length > 0" class="input-wrapper__clear"></div>
                        </div>
                        <div class="collector__hint">Тук можете да посочите детайли, които са важни за нас да знаем при събирането на поръчката</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>

        <b-modal
                ref="modalCheckoutCardAdd"
                id="modalCheckoutCardAdd"
                modal-class="checkout-modal"
                title="Добавяне на нова карта"
                hide-footer
                centered>
            <p>Сума от 0.5 лв. ще бъде блокирана временно и веднага ще върната. Всички операции се извършват от страната на банката.</p>
            <br>
            <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-5">
                    <b-button variant="primary" class="btn-tilda" block @click="paymentAdd()">Продължи</b-button>
                </div>
                <div class="col-sm-5">
                    <b-button variant="light" block @click="$bvModal.hide('modalCheckoutCardAdd')">Не</b-button>
                </div>
                <div class="col-sm-1"></div>
            </div>
        </b-modal>

        <b-modal
                ref="modalCheckoutCardDelete"
                id="modalCheckoutCardDelete"
                modal-class="checkout-modal"
                title="Изтриване на карта"
                hide-footer
                centered>
            <p v-if="paymentToDelete != null">Сигурни ли сте, че искате да изтриете карта {{ paymentToDelete.title }} ?</p>
            <br>
            <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-5">
                    <b-button variant="primary" class="btn-tilda" block @click="paymentDelete()">Да</b-button>
                </div>
                <div class="col-sm-5">
                    <b-button variant="light" block @click="$bvModal.hide('modalCheckoutCardDelete')">Не</b-button>
                </div>
                <div class="col-sm-1"></div>
            </div>
        </b-modal>
    </div>
</template>

<style lang="scss">
    .checkout-modal {
        .modal-dialog {
            width: 500px;
        }

        .col-sm-6 {
            text-align: center;
        }
    }
</style>

<script>
    import Cart from '@/components/Cart.vue'
    import _ from 'lodash'

    import { mapActions } from 'vuex'

    export default {
        name: 'Checkout',
        components: {
            Cart
        },
        data: function () {
            return {
                paymentId: null,
                paymentToDelete: null,
                comment: "",
                coupon: "",
            }
        },
        computed: {
            cart() {
                this.comment = this.$store.state.cart.order != null ? this.$store.state.cart.order.comment : "";
                return this.$store.state.cart.order
            },
            user() {
                return this.$store.state.auth.user
            },
            address() {
                return this.$store.state.cart.order != null ? this.$store.state.cart.order.address : null
            },
            payments() {
                if (this.$store.state.payments.profilePayments != null) {
                    for (let i=0; i<this.$store.state.payments.profilePayments.length; i++) {
                        if (this.$store.state.payments.profilePayments[i].is_default === true) {
                            this.paymentId = this.$store.state.payments.profilePayments[i].id
                        }
                    }
                }
                return this.$store.state.payments.profilePayments
            },
        },
        methods: {
            ...mapActions([
                'checkoutAddress',
                'checkoutComment',
                'checkoutOrder',
                'cartGet',
                'profilePayments',
                'profilePaymentsAdd',
                'profilePaymentsDelete',
                'profilePaymentsDefault'
            ]),

            checkoutSubmit() {
                if (this.paymentId === null) {
                    this.$store.state.error.showErrorMessage = true;
                    this.$store.state.error.errorMessage = "Моля, изберете метод на плащане";
                } else {
                    this.checkoutRequest();
                }
            },
            async checkoutRequest() {
                await this.checkoutOrder({
                    comment: this.comment,
                    payment_id: this.paymentId
                })
            },
            async getCart() {
                await this.cartGet()
            },

            // Comment
            commentChanged() {
                this.checkoutCommentDebounce(this);
            },
            checkoutCommentDebounce: _.debounce((_this) => {
                _this.checkoutCommentRequest()
            }, 500),
            async checkoutCommentRequest() {
                await this.checkoutComment({
                    comment: this.comment
                })
            },

            // Address
            addressChanged() {
                this.addressChangedDebounce(this, this.address);
            },
            addressChangedDebounce: _.debounce((_this, address) => {
                _this.addressChangedRequest(address)
            }, 500),
            async addressChangedRequest(address) {
                await this.checkoutAddress({
                    address: address
                })
            },

            // Payments
            async getPayments() {
                await this.profilePayments()
            },
            paymentChoose(payment) {
                if (payment.type === 'CARD_ADD') {
                    this.$refs['modalCheckoutCardAdd'].show();
                } else {
                    this.$store.state.error.showErrorMessage = false;
                    this.$store.state.error.errorMessage = "";

                    this.paymentDefault(payment);

                    this.paymentId = payment.id;
                }
            },
            async paymentAdd() {
                this.$bvModal.hide('modalCheckoutCardAdd');
                await this.profilePaymentsAdd({})
            },
            paymentDeleteModal(card) {
                this.paymentToDelete = card;
                this.$refs['modalCheckoutCardDelete'].show();
            },
            async paymentDelete() {
                this.$bvModal.hide('modalCheckoutCardDelete');
                await this.profilePaymentsDelete({
                    "id": this.paymentToDelete.id
                })
            },
            async paymentDefault(card) {
                await this.profilePaymentsDefault({
                    "id": card.id
                })
            }
        },
        created() {
            this.getCart();
            this.getPayments();
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/css/base.scss";

    .page {
        margin-top: 70px;
    }

    .checkout {
        margin-top: 35px;
        margin-bottom: 50px;

        &__title {
            font-size: 20px;
            font-weight: 700;
        }
    }

    .contact {
        margin-top: 20px;

        &__title {
            font-weight: 700;
            margin-bottom: 5px;
        }
    }

    .payment {
        margin-top: 25px;

        &__title {
            font-weight: 700;
        }

        &__hint {
            font-size: 13px;
            line-height: 1.4;
            color: #949494;
        }
    }

    .payment-item {
        height: 60px;
        line-height: 60px;
        border-bottom: 1px solid #E8E8E8;
        cursor: pointer;

        &:last-child {
            border: 0;
        }

        &--add {
            color: #2DBE64;
            font-weight: 700;
        }

        &__image {
            width: 50px;
            display: inline-block;

            img {
                max-width: 40px;
                min-width: 35px;
            }
        }

        &__title {
            display: inline-block;

            i {
                position: relative;
                top: 3px;
                left: 3px;
                opacity: 0.1;
                font-size: 16px;

                &:hover {
                    opacity: 0.3;
                }
            }
        }

        &--active &__checkbox {
            border-color: $tilda;
            background-color: transparent;

            &:after {
                background-color: $tilda;
                opacity: 1;
                -webkit-transform: scale(1);
                -ms-transform: scale(1);
                transform: scale(1);
            }
        }

        &__checkbox {
            text-align: right;
            float: right;
            width: 24px;
            height: 24px;
            margin-top: 18px;
            border: 2px solid transparent;
            background-color: #F2F2F2;
            border-radius: 50%;
            position: relative;

            &:after {
                content: " ";
                width: 14px;
                height: 14px;
                position: absolute;
                top: 3px;
                left: 3px;
                border-radius: 50%;
                background-color: transparent;
                opacity: 0;
                -webkit-transform: scale(0);
                -ms-transform: scale(0);
                transform: scale(0);
                transition: opacity 0.2s ease, transform 0.2s ease;
            }
        }
    }

    .address {
        border-top: 1px solid #E8E8E8;
        margin-top: 25px;
        padding-top: 25px;

        &__title {
            font-weight: 700;
            margin-bottom: 5px;
        }

        &__street {
            margin-bottom: 15px;
        }

        &__details {
            margin-left: 1.5em;
            margin-right: 1.5em;
        }
    }

    .input-wrapper {
        align-items: center;
        position: relative;
        width: 100%;
        min-height: 56px;
        margin-bottom: 15px;

        textarea {
            resize: none;
            padding-top: 20px;
            height: 80px;
            line-height: 18px;
        }

        textarea ~ &__label {
            top: 35%;
            background: #f9f9f9;
            padding-top: 8px;
            margin-top: -8px;
            width: calc(100% - 30px);
        }

        &__clear {
            width: 32px;
            height: 32px;
            background: transparent no-repeat center url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.2725 5.81705C11.5738 5.51576 11.5738 5.02727 11.2725 4.72597C10.9712 4.42468 10.4827 4.42468 10.1814 4.72597L7.99837 6.90899L5.81721 4.72784C5.51592 4.42654 5.02742 4.42654 4.72613 4.72784C4.42483 5.02913 4.42483 5.51762 4.72613 5.81892L6.90729 8.00008L4.72597 10.1814C4.42468 10.4827 4.42468 10.9712 4.72597 11.2725C5.02727 11.5738 5.51576 11.5738 5.81705 11.2725L7.99837 9.09116L10.1815 11.2743C10.4828 11.5756 10.9713 11.5756 11.2726 11.2743C11.5739 10.973 11.5739 10.4846 11.2726 10.1833L9.08945 8.00008L11.2725 5.81705Z' fill='%23B4B4B4'/%3E%3C/svg%3E");
            position: absolute;
            top: 12px;
            right: 8px;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            -moz-transition: all .3s;
            transition: all .3s;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            opacity: 0;
            border: 0;
            cursor: pointer;
            z-index: 2;
        }

        &__label {
            display: inline-block;
            visibility: visible;
            user-select: none;
            position: absolute;
            color: rgb(131, 130, 135);
            font-size: 14px;
            line-height: 16px;
            pointer-events: none;
            transition: top 0.2s ease 0s, transform 0.2s ease 0s, font-size 0.2s ease 0s;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            z-index: 1;
        }

        &__input {
            position: relative;
            color: rgb(25, 25, 25);
            line-height: 24px;
            letter-spacing: 0.15px;
            height: 100%;
            width: 100%;
            min-height: 56px;
            padding-left: 14px;
            padding-top: 15px;
            padding-right: 14px;
            border: 2px solid #F9F9F9;
            background: #F9F9F9;
            border-radius: 4px;
            transition: border-color 0.2s ease 0s, font-size 0.2s ease 0s, letter-spacing 0.2s ease 0s;


            &::-webkit-input-placeholder { /* WebKit browsers */
                color: #fff;
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color: #fff;
                opacity: 1;
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color: #fff;
                opacity: 1;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10+ */
                color: #fff;
            }
        }

        &__input:focus ~ &__label,
        &__input:not(:placeholder-shown) ~ &__label {
            font-size: 12px;
            letter-spacing: 0.4px;
            top: 8px;
            transform: translateY(0px);
        }

        &__clear:hover,
        &__input:hover ~ &__clear,
        &__input:focus ~ &__clear {
            opacity: 1;
        }
    }

    .coupons {
        margin-top: 10px;
        border-top: 1px solid #E8E8E8;
        padding-top: 20px;
        padding-bottom: 5px;

        &__title {
            margin-bottom: 10px;
            margin-top: 5px;
            font-weight: 700;
        }

        &__input {


            .btn {
                width: 100%;
                height: 56px;
            }
        }
    }

    .summary {
        margin-top: 10px;
        border-top: 1px solid #E8E8E8;
        padding-top: 20px;

        .alert {
            margin-top: 20px;
        }

        &__field {
            margin-bottom: 10px;

            &-name {
                display: inline-block;

                &--bold {
                    font-weight: 700;
                }
            }

            &-value {
                font-weight: 700;
                display: inline-block;
                float: right;

                &--red {
                    color: #FF3B30;
                }

                &--discount {
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 9px;
                        width: 100%;
                        height: 2px;
                        display: block;
                        background: #FF3B30;
                        opacity: 0.7;
                        -webkit-transform: rotate(-3deg);
                        transform: rotate(-3deg);
                    }
                }
            }
        }

        .btn {
            width: 100%;
            height: 56px;
            margin-top: 8px;
        }
    }

    .collector {
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;

        &__hint {
            font-size: 12px;
            color: #949494;
            margin-top: -15px;
        }
    }
</style>